/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // var $html = $('html');
        // var $navWrap = $('.katz--nav-wrap');
        // var $navWrapInner = $navWrap.children('.katz--nav-wrap--inner');

        // var setMaxHeight = function() {
        //   // set max height for mobile menu
        //   var maxHeight = $(window).height() - $('#header').outerHeight() - $('.katz--header-alert').outerHeight() - $('.katz--mobile-header-phone').outerHeight();
        //   maxHeight = '100%';

        //   $navWrapInner.css({maxHeight: maxHeight});
        // };

        var $html = $('html');
        var $navWrap = $('.katz--nav-wrap');
        var $navWrapInner = $navWrap.children('.katz--nav-wrap--inner');
        var $pageHeader = $('.site-header');

        var setMaxHeight = function() {
          // set max height for mobile menu
        var maxHeight = $(window).height() - $pageHeader.outerHeight();
        maxHeight = '2000px';

        $navWrapInner.css({maxHeight: maxHeight});
        };


        var setupEqualHeight = function() {
          var windowWidth = $(window).width();
          $('.katz--equal-height-target').css({height: 'auto'});
          if (windowWidth > 599) {
            $('.katz--equal-height').each(function() {
                var tallest = 0;
                var $parent = $(this);
                var $items = $parent
                  .find($parent.data('equal-height-selector'))
                  .each(function() {
                    tallest = $(this).outerHeight() > tallest ? $(this).outerHeight() : tallest ;
                  });
                $items.css({height: tallest});
            });
          }
        };

        var setTooltipPositions = function() {
          $('.site--tooltip--text--inner').each(function() {
            var $inner = $(this);
            var $tooltip = $inner.parents('.site--tooltip');

            if ($inner.offset().left < 0) {
              $tooltip
                .removeClass('site--tooltip-right')
                .addClass('site--tooltip-left');
            }

            else if ($inner.offset().left + $inner.width() > $(window).width()) {
              $tooltip
                .removeClass('site--tooltip-left')
                .addClass('site--tooltip-right');
            }

            else {
              $tooltip
                .removeClass('site--tooltip-left site--tooltip-right');
            }
          });
        };

        WebFont.load({
          google: {
            families: ['Roboto+Slab', 'Roboto']
          },
          active: setMaxHeight
        });

        // Mobile menu
        var $hamburger = $('.katz--menu-button').click(function(event) {
          $html.toggleClass('katz--mobile-menu-expanded');
          $('html, body').animate({
            scrollTop: 0
          });
        });

        $navWrap
          .find('.menu-item-has-children').click(function(event) {
            event.stopPropagation();
            $(this)
              .children('.sub-menu').slideToggle()
                .find('.katz--menu-expanded').removeClass('katz--menu-expanded').end()
                .find('.sub-menu').hide().end()
              .end()
            .toggleClass('katz--menu-expanded');
          }).end()

          .children('a').click(function(event) {
            event.stopPropagation();
          });

        // trigger responsive stuff on resize
        var resizeTimeout;
        $(window).on('resize.site', function(event) {
          if (resizeTimeout) {
            clearTimeout(resizeTimeout);
          }

          resizeTimeout = setTimeout(function() {
            setMaxHeight();
            setupEqualHeight();
            setTooltipPositions();
          } , 250);
        }).trigger('resize.site');

        // setup lazy loading
        $('[data-src!=""]').not('.site--hero-slider .site--hero-image').Lazy();

        // setup header search
        var $searchSubmit = $('#header .widget .search-form input[type="submit"]');
        var $searchForm = $searchSubmit.parents('.search-form');
        // expand search box on search results page
        if ($('body').hasClass('search-results')) {
          $searchForm.addClass('site--expanded');
        }
        // setup expanding search box
        $searchSubmit.on('click.site', function(event) {
          event.preventDefault();
          var $input = $searchForm.find('input[name="s"]');

          if ($input.val().trim()) {
            $searchForm.submit();
          }
          else {
            $searchForm.toggleClass('katz--expanded');
          }
        });

        // setup search box close button
        $('.site--search--close-button').on('click.site', function(event) {
          $(this)
            // hide search input
            .parents('.search-form').removeClass('katz--expanded')
            // reset search input
            .find('input[name="s"]').val(null);
        });

        // if current page is not in menu,
        // try to select its ancestors in the sidebar

        // var pageName;
        // $.each($('body').attr('class').split(' '), function (index, className) {
        //     if (className.indexOf('financial-assistance') === 0) {
        //       $pageName = className;
        //       var $anchor = $("a:contains('Prospective Families')");
        //       var $menuItem = $anchor.parent();
        //       $menuItem.addClass('current_menu_item');
        //       $menuItem.addClass('current_page_item');
        //       var $anchor = $("a:contains('Current Families')");
        //       $menuItem = $anchor.parent();
        //       $menuItem.removeClass('current_menu_parent');
        //       $menuItem.css('display', 'none');
        //     }
        // });
        // alert($pageName);

        var $anchor, $menuItem;

        // set the current page for CPT people
        var $people = $('.single-people');
        if ($people.length) {
          $anchor = $("a:contains('Prospective Families')");
          $menuItem = $anchor.parent();
          $menuItem.addClass('current_menu_item');
          $menuItem.addClass('current_page_item');
        }

        var $sidebarMenu, $currentMenuItem, $parents, $parentLinks;
        var $parent, url, relativeUrl;

        // Prospective Families
        $sidebarMenu = $('#sidebar .menu');
        $currentMenuItem = $sidebarMenu.find('.current-menu-item, .current_page_item');
        if ($currentMenuItem.length === 0) {
          // alert("searching for contact-us");
          $sidebarMenu = $('#contact-us-menu .menu');
          $currentMenuItem = $sidebarMenu.find('.current-menu-item, .current_page_item');
            if ($currentMenuItem.length === 0) {
            // alert("searching for donate-menu");
            $sidebarMenu = $('#donate-menu .menu');
            $currentMenuItem = $sidebarMenu.find('.current-menu-item, .current_page_item');
            // if ($currentMenuItem.length === 0){
            //   alert("not found");
            // }
            // else {alert("found");}
          }
        }

        // This is not really doing anything cuz breadcrumb-containier is not being used
        if (!$currentMenuItem.length) {
          $parents = $('.--breadcrumb-container a');
          if ($parents.length) {
            $($parents.get().reverse()).each(function() {
              $parent = $(this);
              url = $parent.attr('href');
              relativeUrl = url.split(document.domain)[1].slice(1);

              // end loop if relative URL is blank or /
              if (!relativeUrl || '/' === relativeUrl) {
                return false;
              }

              // find any sidebar links for that URL, with or without trailing slash
              $parentLinks = $sidebarMenu
                .find('a[href$="' + relativeUrl + '"], a[href$="' + relativeUrl + '"]')
                  .parent().addClass('current_page_item')
                    .parents('li').addClass('current_page_ancestor')
                  .end()
                .end();

              if ($parentLinks.length) {
                return false;
              }
            });
          }
        }



        // setup collapsible sidebar
        var $items = $sidebarMenu.find('> .menu-item > .sub-menu > .menu-item-has-children');
        $items
          .each(function(index) {
            var $item = $(this).css('position', 'relative');
            var $submenu = $item.find('> .sub-menu');
            var submenuId = 'sub-menu-' + index;
            var isAncestor = $item.hasClass('current_page_ancestor') || $item.hasClass('current-menu-ancestor') || $item.hasClass('current_page_item') || $item.hasClass('current-menu-item');

            $submenu.attr('id', submenuId);
            $submenu.addClass(isAncestor ? 'collapse show' : 'collapse');

            $item.prepend('<a class="site--toggle" role="button" data-toggle="collapse" href="#' + submenuId + '" aria-expanded="' + (isAncestor ? 'true' : 'false') + '" aria-controls="' + submenuId + '"><span class="caret"></span></a>');
          })
          .children('a:not([href])').click(function(event) {
            event.preventDefault();
            $(this).siblings('.site--toggle').click();
          });

        // initialize datatables
        var $tables = $('.site--posts-table table');
        $tables.each(function() {
          var $table = $(this);
          var $th = $table.find('thead th');
          var initialSortCol = $table.data('initial-sort-column');
          var initialSortDir = ("DESC" === $table.data('initial-sort-direction')) ? 'desc' : 'asc' ;
          var order = [];

          if (initialSortCol) {
            order = [[initialSortCol, initialSortDir]];
          }

          $table.DataTable({
            paging: false,
            info: false,
            bFilter: false,
            responsive: true,
            order: order,
            columnDefs: [
              {
                targets  : 'site--unsortable',
                orderable: false
              },
              {
                targets  : 'site--date',
                sType: 'date'
              },
              {
                targets  : 'site--column-xs',
                width: '50px'
              },
              {
                targets  : 'site--column-sm',
                width: '100px'
              },
              {
                targets  : 'site--column-md',
                width: '200px'
              },
              {
                targets  : 'site--column-lg',
                width: '350px'
              },
              {
                targets  : 'site--column-xl',
                width: '500px'
              }
            ]
          });
        });

        // initialize lightslider
        $('.site--hero-slider').each(function() {
          var $slider = $(this);

          var slideCallback = function(el) {
            setTimeout(function() {
              var $nextSlide = $slider.children('.active');
              var currentBackgroundImage = $nextSlide.css('background-image');
              if (!currentBackgroundImage || 'none' === currentBackgroundImage) {
                $nextSlide.css('background-image', "url('" + $nextSlide.data('src') + "')");
              }
            }, 100);
          };

          $slider.lightSlider({
            item: 1,
            slideMargin: 0,
            auto: true,
            loop: true,
            pauseOnHover: true,
            pause: 5000,
            enableTouch: false,
            onSliderLoad: slideCallback,
            onBeforeSlide: slideCallback,
            onAfterSlide: slideCallback
          });
        });

        // setup price request form modal
        $('#site--membership-form-modal').on('show.bs.modal', function(event) {
          var $modal = $(this);
          var $select = $modal.find('.site--membership-category-select select');
          var $link = $(event.relatedTarget);
          var targetVal = $link.data('subcategory');

          $select.val(targetVal);
        });

        //Scroll Events Calendar to show
        $('.scroller-content').scrollTop(540);

        // setup mobile section tabs
        var $mobileSectionTabs = $('.katz--section-tabs-mobile--item');
        $mobileSectionTabs.find('.katz--section-tabs-mobile--item-content').slideUp();

        $('.katz--section-tabs-mobile--item > header > button').click(function() {
          $(this)
            .parents('.katz--section-tabs-mobile--item').addClass('katz--active')
              .find('.katz--section-tabs-mobile--item-content').slideDown().end()
            .siblings().removeClass('katz--active')
              .find('.katz--section-tabs-mobile--item-content').slideUp().end()
            .end()
          ;
        });

        // show vimeo videos when they start playing
        var $vimeoIframes = $('iframe[src*="vimeo"]');
        $vimeoIframes.each(function(index, iframe) {
          var $iframe = $(iframe);
          var player = new Vimeo.Player($iframe);

          var showVideo = function(event) {
            setTimeout(function() {
              $iframe.addClass('visible');
            }, 1000);
          };

          player.on('loaded', showVideo);
         });

        // show other videos when they start playing
        var $otherVideos = $('.iframe-wrap > video');
        $otherVideos.each(function(index, video) {
          var $video = $(video);

          if ($video.is(':visible')) {
            $video.attr('src', $video.data('src'));
          }

          $video
            .on('play', function() {
              $video.addClass('visible');
            })
            .on('click', function() {
              if (video.paused) {
                video.play();
              }
              else {
                video.pause();
              }
            })
            .filter('[autoplay]')[0].play();
        });

        // autoplay video header on mobile
        // var $mobileVideo = $('.page-header.video .iframe-wrap--mobile-embed');
        // if ($mobileVideo.length) {
        //   var player = new Vimeo.Player($mobileVideo[0]);
        //   player.play();
        // }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
        'home': {
          init: function() {
            // JavaScript to be fired on the home page
          },
          finalize: function() {
            // JavaScript to be fired on the home page, after the init JS
          }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
          init: function() {
            // JavaScript to be fired on the about us page
          }
        }
      };

      // The routing fires all common scripts, followed by the page specific scripts.
      // Add additional events for more control over timing e.g. a finalize event
      var UTIL = {
        fire: function(func, funcname, args) {
          var fire;
          var namespace = Sage;
          funcname = (funcname === undefined) ? 'init' : funcname;
          fire = func !== '';
          fire = fire && namespace[func];
          fire = fire && typeof namespace[func][funcname] === 'function';

          if (fire) {
            namespace[func][funcname](args);
          }
        },
        loadEvents: function() {
          // Fire common init JS
          UTIL.fire('common');

          // Fire page-specific init JS, and then finalize JS
          $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
            UTIL.fire(classnm);
            UTIL.fire(classnm, 'finalize');
          });

          // Fire common finalize JS
          UTIL.fire('common', 'finalize');
        }
      };

      // Load Events
      $(document).ready(UTIL.loadEvents);





    })(jQuery); // Fully reference jQuery after this point.
